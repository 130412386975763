<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="添加污染物"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="left"
      >
        <el-form-item
          label-width="100px"
          label="污染物名称"
          prop="pollutionName"
        >
          <el-input
            v-model="formData.pollutionName"
            placeholder="请输入污染物名称"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="污染物编号"
          prop="pollutionCode"
        >
          <el-input
            v-model="formData.pollutionCode"
            placeholder="请输入污染物编号"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="污染物单位"
          prop="pollutionUnit"
        >
          <el-input
            v-model="formData.pollutionUnit"
            placeholder="请输入污染物单位"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="污染物类型"
          prop="pollutionType"
        >
          <el-select
            v-model="formData.pollutionType"
            placeholder="请输入污染物类型"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in options.pollutionTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div>
          <slot name="footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
          </slot>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { addPollution } from "@/api/pollution";
import { getMonitoryPointTypeOptions } from "@/api/monitory-point-type";

export default {
  name: "PollutionInfoAdd",
  components: {},
  props: { dialogVisible: Boolean },
  data() {
    return {
      visible: false,
      formData: {
        pollutionName: "",
        pollutionCode: "",
        pollutionUnit: "",
        pollutionType: "",
      },
      options: {
        pollutionTypeOptions: [],
      },
      rules: {
        pollutionName: [
          {
            required: true,
            message: "请输入污染物名称",
            trigger: "blur",
          },
        ],
        pollutionCode: [
          {
            required: true,
            message: "请输入污染物编号",
            trigger: "blur",
          },
        ],
        pollutionUnit: [
          {
            required: true,
            message: "请输入污染物单位",
            trigger: "blur",
          },
        ],
        pollutionType: [
          {
            required: true,
            message: "请输入污染物类型",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  created() {
    this.getOptions();
  },
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        const requestBody = {
          id: undefined,
          pollutionName: this.formData.pollutionName,
          pollutionCode: this.formData.pollutionCode,
          pollutionUnit: this.formData.pollutionUnit,
          pollutionType: this.formData.pollutionType,
        };
        addPollution(requestBody).then(() => {
          this.$parent.getList();
        });
        this.close();
      });
    },
    getOptions() {
      getMonitoryPointTypeOptions().then((result) => {
        this.options.pollutionTypeOptions = result.data;
      });
    },
  },
};
</script>
<style></style>

<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-chat-dot-round"></i> 信息管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>污染物管理</el-breadcrumb-item>
        <el-breadcrumb-item>污染物信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-input
            clearable
            size="mini"
            v-model="pollutionNameOrCodeInput"
            placeholder="污染物名字 / 编号"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
          <el-button
            @click="handleExport"
            plain
            type="warning"
            icon="el-icon-download"
            size="mini"
          >
            导出
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <el-button
          @click="handleAddPollution"
          type="primary"
          icon="el-icon-edit"
          size="mini"
        >
          添加污染物
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="pollutionName" label="污染物名字">
        </el-table-column>
        <el-table-column prop="pollutionCode" label="污染物编号">
        </el-table-column>
        <el-table-column prop="pollutionUnit" label="污染物单位">
        </el-table-column>
        <el-table-column prop="pollutionType" label="污染物类型">
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <pollution-info-add
      :dialog-visible="dialogVisiblePollutionAdd"
      @updateVisible="closePollutionAdd"
    >
    </pollution-info-add>
    <pollution-info-edit
      :dialog-visible="dialogVisiblePollutionEdit"
      :row="editPollution"
      @updateVisible="closePollutionEdit"
    >
    </pollution-info-edit>
  </div>
</template>

<script>
import PollutionInfoAdd from "@/views/info/pollution/pollutionInfo/dialog/PollutionInfoAdd";
import PollutionInfoEdit from "@/views/info/pollution/pollutionInfo/dialog/PollutionInfoEdit";
import { getTable, deletePollution } from "@/api/pollution";
import { exportPollutionInfo } from "@/api/export";

export default {
  name: "PollutionInfo",
  components: { PollutionInfoEdit, PollutionInfoAdd },
  data() {
    return {
      pollutionNameOrCodeInput: "",
      editPollution: {},
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisiblePollutionAdd: false,
      dialogVisiblePollutionEdit: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClear() {
      this.pollutionNameOrCodeInput = "";
    },
    handleSearch() {
      console.log(this.pollutionNameOrCodeInput);
      this.getList();
    },
    handleExport() {
      exportPollutionInfo();
    },
    handleAddPollution() {
      this.dialogVisiblePollutionAdd = true;
    },
    closePollutionAdd() {
      this.dialogVisiblePollutionAdd = false;
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.editPollution = JSON.parse(JSON.stringify(row));
      this.dialogVisiblePollutionEdit = true;
    },
    closePollutionEdit() {
      this.dialogVisiblePollutionEdit = false;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.editPollution = row;
      this.$confirm("此操作将永久删除污染物, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const params = { id: row.id };
          deletePollution(params).then(() => {
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      const param = {
        pollutionNameOrCode: this.pollutionNameOrCodeInput,
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        this.tableData = data.list;
      });
    },
  },
};
</script>

<style scoped></style>
